.contract__page__container {
  margin-bottom: -15px;
}
.contract__page {
  width: 100%;
  display: flex;
  padding-top: 150px;
  padding-bottom: 100px;
  justify-content: center;
}

.contract__overall {
  border-radius: 20px;
  box-shadow: 0px 0px 20px #cccccc88;
  border: solid #000 4px;
  background: #ccc;
  overflow: hidden;
  height: auto;
  padding-bottom: 20px;
  z-index: 1;
}
.contract__header {
  display: flex;
  background: #000;
  justify-content: center;
  height: 100px;
  -webkit-appearance: none;
}
.contract__box {
  width: 650px;
  max-width: 650px;
  justify-content: center;
  text-align: center;
  -webkit-appearance: none;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
}
.my__form {
  -webkit-appearance: none;
}
label {
  display: none;
  -webkit-appearance: none;
}

.register__button {
  padding: 15px 0px 15px 0px;
  background: #131313;
  font-family: "Courier";
  color: #fff;
  font-size: 18px;
  -webkit-appearance: none;
}

.register__button:hover {
  font-weight: bold;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.terms {
  padding-bottom: 10px;
  -webkit-appearance: none;
}
.nftc__link2 {
  color: #6c1313;
  transition: all 0.5s;
  text-decoration: none;
  -webkit-appearance: none;
}
.nftc__link2:hover {
  color: #b90000;
  font-weight: bold;
  -webkit-appearance: none;
}
.large__checkbox {
  height: 20px;
  width: 20px;
}
.checkbox__div {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 2px;
  -webkit-appearance: none;
}
.nftc__form {
  padding: 20px;
  padding-top: 20px;
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}
@media screen and (max-width: 720px) {
  .review__content__div {
    flex-direction: column;
    align-items: center;
  }
  .my__columns {
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
  .col1 {
    width: 100%;
  }
  .col2 {
    width: 100%;
    margin-top: 20px;
  }
  .contract__overall {
    height: auto;
    width: 350px;
  }
  .contract__box {
    width: 330px;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
  }
  .text__info__div {
    width: 100%;
  }
  .token__div {
    width: 100%;
    margin-top: 20px;
  }
  .confirmation__button__div {
    margin-top: 5px;
    flex-direction: column;
    align-items: center;
  }
  .c__confirm__button {
    margin-top: 10px;
  }
  .text__info__scroll__area {
    max-height: min-content;
    overflow-y: hidden;
  }
  .text__info__div {
    max-height: min-content;
  }
  .admin__button {
    max-width: 90%;
    min-width: 300px;
  }
}
