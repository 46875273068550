.footer {
  background: #000;

  color: #fff;

  background-position: center;
  background-size: cover;
  z-index: 0;
  width: 100%;
  box-sizing: border-box;
}
.copyright {
  font-size: 16px;
  color: #fff;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.social__box {
  width: 70%;
  display: inline-block;
}
.contact__box {
  width: 30%;
  display: inline-block;
  text-align: right;
  vertical-align: top;
  padding-top: 10px;
}
.contact__us {
  padding-bottom: 20px;
}
.contact__email {
  color: #fff;
  text-decoration: none;
  line-height: auto;
  margin-top: 20px;
}
.contact__email:hover {
  color: #ff0000;
}
.social__icon__box {
  display: flex;
  padding-top: 10px;
}
.follow__us {
  padding-top: 10px;
}

.fa {
  font-size: 20px;
  height: 30px;
  width: 30px;
  text-align: center;
  text-decoration: none;
  background: #fff;
  vertical-align: center;
  line-height: 30px;
  color: #000;
  border-radius: 5px;
  margin-right: 10px;
  transition: all 0.5s;
}

/* Add a hover effect if you want */
.fa:hover {
  opacity: 0.7;
  background-color: #ff0000;
  transform: scale(1.1);
  cursor: pointer;
}

.bottom__footer {
  padding-left: 30px;
  padding-right: 30px;
  background: #000;
  height: 110px;
}

.foot__thirds {
  width: 100%;
  text-align: center;
}
.footer__menu__right {
  justify-content: center;
  text-align: center;
  display: flex;
  overflow: hidden;
}
.footer__menu__item {
  padding: 10px;
  border-right: solid #fff 1px;
  border-top: solid #fff 1px;
  border-radius: 0px;
  color: #fff;
  text-decoration: none;
  display: block;
  width: 50%;
  height: 60px;
  line-height: 40px;
  text-align: center;
  transition: all 0.7s;
  min-width: 170px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  background: #222;
  box-sizing: border-box;
}
.footer__menu__item:hover {
  background-color: #ff0000;
  color: #fff;
  font-size: 30px;
}

.under__footer {
  display: flex;
  border-left: solid #fff 1px;
  border-bottom: solid #fff 1px;
}
.under__footer__mobile {
  border-left: solid #fff 1px;
  border-bottom: solid #fff 1px;
}
a span {
  height: 1px;
  width: 1px;
  position: absolute;
  overflow: hidden;
  top: -10px;
}

.footer__black__fade {
  background: linear-gradient(0deg, rgba(0, 0, 0), rgba(0, 0, 0, 0));
  height: 200px;
  margin-top: -200px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.cancel__mobile__div {
  display: flex;
  text-align: center;
  justify-content: center;
  padding-bottom: 5px;
  border-radius: 5px;
}
.cancel__mobile__button {
  display: flex;
  align-items: center;
  background-color: #22222270;
  width: 90%;
  max-width: 400px;
  height: 40px;
  justify-content: center;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  margin-bottom: -80px;
  z-index: 3;
}
.cancel__mobile__button:hover {
  transition: all 0.5s;
  background-color: #222;
}

@media only screen and (max-width: 949px) {
  .under__footer {
    display: none;
  }

  .social__box {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .social__icon__box {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .footer__hightlights {
    height: 50px;
  }
  .footer__menu__item {
    font-size: 20px;
  }
  .footer__menu__item:hover {
    font-size: 24px;
  }
  .cancel__mobile__button {
    max-width: 1000px;
    margin-bottom: 10px;
    background-color: #222222bb;
  }
}

@media only screen and (min-width: 950px) {
  .under__footer__mobile {
    display: none;
    visibility: hidden;
  }
}
@media only screen and (min-width: 550px) {
  .super__tiny__only {
    display: none;
    visibility: hidden;
  }
}
