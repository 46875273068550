/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;

  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.nav {
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  z-index: 10;
  padding: 20px;

  transition-timing-function: ease-in;
  transition: all 0.5s;
  background-color: rgba(17, 17, 17, 0.33);
}

.nav__black {
  background-color: #000;
}

.nav__logo {
  width: 180px;
  object-fit: contain;
  position: fixed;
  left: 20px;
  cursor: pointer;
  background: #b90000;
}

.nav__avatar {
  position: fixed;
  right: 20px;
  width: 50px;
  object-fit: contain;
  border-radius: 10px;
  transition: transform 450ms;
  border: solid #333 1px;
}
.nav__avatar:hover {
  transform: scale(1.1);
  cursor: pointer;
}
.prompt {
  position: fixed;

  text-align: center;
  width: 100%;
  color: white;
}
.menu__pop {
  position: fixed;
  height: 123px;
  width: 200px;
  background: #222;
  right: 10px;
  top: 90px;
  border: solid #fff;
  border-radius: 10px;
  box-shadow: #000 10px;
  z-index: 100;
  overflow: hidden;
}
.menu__text {
  color: #fff;
}
.menu__item {
  display: block;
  text-align: center;
  text-decoration: none;
  color: #fff;
  border-bottom: 1px solid #fff;
  height: 40px;
  line-height: 40px;
  font-family: "Space Grotesk";
  font-weight: bold;
  transition: all 0.5s;
}
.menu__item:hover {
  background: #b90000;
  font-size: 18px;
  color: #fff;
}
.bottom__menu__item {
  border: none;
  border-radius: 0px 0px 10px 10px;
}
.top__menu__item {
  border-radius: 10px 10px 0px 0px;
}
@font-face {
  font-family: "Saira";
  src: url(https://fonts.googleapis.com/css?family=Saira);
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Oxygen";
  src: url(https://fonts.googleapis.com/css?family=Oxygen);
  font-weight: normal;
  font-style: normal;
}
.network__mismatch__prompt {
  display: flex;
  z-index: 99;
  position: absolute;
  background: #fff;
  width: 100%;
  flex-direction: column;
  text-align: center;
  max-width: 300px;
  font-size: 16px;
  padding-top: 5px;
  font-family: "Saira";
  border-radius: 10px;
  overflow: hidden;
  margin-top: 10px;
  border: solid 2px #000;
  min-height: 30px;
  justify-content: center;
}
.network__mismatch__div {
  display: flex;
  width: 100%;
  justify-content: center;
}
.two__buttons__div {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  height: 40px;
}
.network__prompt__button {
  width: 50%;
  height: 40px;
  font-family: "Saira";
  background: #333;
  color: #fff;
  border: none;
  border-top: 2px solid #000;
  cursor: pointer;
}
.full__width__button {
  width: 100%;
}
.network__prompt__button__right {
  border-left: solid 2px #000;
  box-sizing: border-box;
}
.network__prompt__button:hover {
  transition: all 0.5s;
  background: #111;
}
.network__prompt__button:hover .gas__div {
  transition: all 0.5s;
  opacity: 1;
}
.no__metamask__text {
  font-size: 16px;
}
.wallet__balance__prompt {
  background: #30303045;
  color: #fff;
}

@media screen and (max-width: 725px) {
  .network__mismatch__prompt {
    max-width: 100%;
    height: 50px;
    margin-top: 90px;
    margin-bottom: -90px;
    border-radius: 0px;
    position: relative;
    z-index: 3;
  }
  .network__prompt__button {
    height: 30px;
  }
  .gas__div {
    max-height: 30px;
  }
  .nav {
    background-color: #000;
  }
  .nav__mobile__hide {
    display: none;
  }
}
.buy__weth__button:hover span {
  display: none;
}
.buy__weth__button:hover:before {
  transition: all 0.5s;
  content: "BUY WETH";
}
.buy__matic__button:hover span {
  display: none;
}
.buy__matic__button:hover:before {
  transition: all 0.5s;
  content: "MY ACCOUNT";
}
.buy__matic__button {
  background: #44444489;
}
.buy__weth__button {
  background: #44444489;
}
.gas__div {
  width: 100%;
  display: flex;
  font-size: 13px;

  position: absolute;
  align-self: flex-end;
  justify-content: flex-end;
  align-items: center;
  opacity: 0.7;
  height: 40px;
  padding: 5px;
  box-sizing: border-box;
  pointer-events: none;
}
.gas__div i {
  padding-right: 5px;
}
.wallet__info__icon {
  align-self: flex-end;

  opacity: 0.6;
  cursor: pointer;
  width: 30px;
}
.wallet__info__icon i {
  padding-right: 5px;
}
.wallet__info__icon:hover {
  transition: all 0.5s;
  opacity: 1;
}
.wallet__prompt__top {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.icon__spacer__div {
  width: 20px;
}
.menu__bkg {
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 100;
}
