.blog__page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  background-color: #333;
  min-height: 100vh;
  box-sizing: border-box;
  margin-bottom: -230px;
  padding-bottom: 180px;
}

.blog__header {
  background-image: url("./../../../public/media/banner5.jpg");
  object-fit: contain;
  text-align: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 400px;
  height: 600px;
  display: flex;
  flex-direction: column;
  color: #fff;
}
.blog__header__contents {
  margin-left: 0px;
  padding-top: 180px;
  height: 300px;
  display: flex;
  justify-content: center;
}

.blog__title {
  font-size: 50px;
  font-weight: 700;
  padding-bottom: 0.3rem;
  font-family: "Oxygen";
  text-transform: uppercase;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
.blog__subtitle {
  font-size: 32px;
  font-family: "Saira";

  padding: 20px;
}
.blog__text__background {
  background: #fff;
  color: #000;
  font-family: "Saira";
  width: fit-content;
  height: fit-content;
  border-radius: 10px;
  padding: 0px;
  box-shadow: 20px 20px 200px rgba(0, 0, 0, 0.6);
  border: solid 3px #000;
}
.blog__fadeBottom {
  height: 220px;
  background-image: linear-gradient(180deg, transparent, rgb(37, 37, 37), #333);
}
.blog__header__logo {
  background: #000;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.blog__header__logo__image {
  max-width: 250px;
}
.blog__cards__div {
  display: flex;
  padding-bottom: 150px;
  flex-wrap: wrap;
  max-width: 1300px;
  align-self: center;
}
.blog__card__div {
  width: 33%;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}
.blog__card {
  display: flex;
  width: 90%;
  flex-direction: column;
  justify-content: space-between;
  z-index: 3;
  background: #fff;
  border: #000 solid 3px;
  border-radius: 10px;
  overflow: hidden;
  height: 780px;
}
.blog__card__thumbnail__image {
  width: 100%;
  cursor: pointer;
  transition: all 0.5s;
}

.blog__card__thumbnail__image:hover {
  filter: saturate(200%);
}
.blog__card__title {
  font-size: 28px;
  font-family: "Saira";
  padding-top: 20px;
  padding-bottom: 20px;
}
.blog__card__info__div {
  padding-left: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
}
.blog__card__metadata__div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-top: 2px solid #000;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 10px;
  font-family: "Courier";
  font-size: 14px;
  box-sizing: border-box;
}
.blog__read__more__button {
  font-size: 30px;
  font-family: "BenchNine";
  text-transform: uppercase;
  background: none;
  border: none;
  width: 90%;
  text-align: left;
  padding: 0px;
  cursor: pointer;
  color: #f00000;
  margin-top: 20px;
}
.blog__card__preview {
  line-height: 28px;
  font-size: 18px;
}
.blog__page__buttons {
  width: 100%;
  display: flex;
  justify-content: center;
}
.page__button {
  width: 150px;
  margin-left: 10px;
  font-family: "BenchNine";
  font-size: 24px;
  background: #ddd;
  border: none;
  z-index: 3;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
}
.page__button:disabled {
  background: #aaa;
  color: #555;
  pointer-events: none;
}
@media screen and (max-width: 720px) {
  .blog__text__background {
    max-width: 90%;
  }
  .blog__title {
    font-size: 34px;
  }
  .blog__subtitle {
    font-size: 22px;
  }
  .blog__card__div {
    width: 100%;
  }
  .blog__card {
    max-width: 400px;
  }
}
@media screen and (max-width: 1000px) and (min-width: 726px) {
  .blog__card__div {
    width: 50%;
  }
}
